import { Component, Inject, inject, OnInit } from '@angular/core';

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-update-tool',
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
  ],
  templateUrl: './update-tool.component.html',
  styleUrls: ['./update-tool.component.scss'],
})
export class UpdateToolComponent implements OnInit {
  private http = inject(HttpClient);

  branches: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<UpdateToolComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
  }

  ngOnInit() {
    this.http.get('/api/deploy/branches').subscribe(
      (data: any) => {
        if (this.data.target === 'prod-tenants') {
          this.branches = data['data'].filter((b: any) =>
            b.branch.startsWith('deploy-')
          );
        } else {
          this.branches = data['data'];
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onUpdateClick(): void {
    let build_defn: any = {
      branch: this.data.branch,
      source: this.data.source,
    };
    if (this.data.target === 'uat') {
      build_defn = {
        ...build_defn,
        deploy_targets: ['uat'],
        deploy_mode: this.data.force_full_env ? 'full-env' : 'auto',
        deploy_type: this.data.target,
        immediate: this.data.immediate,
      };
    } else if (this.data.target === 'appdot') {
      build_defn = {
        ...build_defn,
        deploy_targets: ['prod-app'],
        immediate: true,
      };
    } else {
      // prod-tenants
      build_defn = {
        ...build_defn,
        deploy_targets: this.data.targets,
        deploy_type: this.data.target,
        deploy_mode: 'all-code',
        immediate: true,
      };
    }

    const tail =
      this.data.target !== 'appdot'
        ? '/api/deploy/tenant'
        : '/api/deploy/appdot';
    const body = JSON.stringify(build_defn);
    this.http.post(tail, body).subscribe(
      (data) => {
        this.dialogRef.close({ queued: true });
      },
      (error) => {
        console.error(error);
      }
    );
  }
}
