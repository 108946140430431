import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dataSummary', standalone: true })
export class DataSummaryPipe implements PipeTransform {
  transform(data: any) {
    if (data === undefined) {
      return 'unknown';
    }
    return `${data['desktop_count']} desktop users; ${data['mobile_count']} mobile users; ${data['portal_job_count']} portal jobs`;
  }
}
