import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes, CanActivateFn, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { catchError, map, of } from 'rxjs';
import { CredentialsComponent } from './credentials/credentials.component';
import { DeploymentsComponent } from './deployments/deployments.component';
import { ActivityComponent } from './activity/activity.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { DeployComponent } from './deploy/deploy.component';

export const authGuard: CanActivateFn = (route, state) => {
  const http = inject(HttpClient);
  const router = inject(Router);

  return http.get('api/session/check').pipe(
    catchError((err) => {
      // propogate the error as we can to the auth-landing component
      router.navigate(['/login']);
      return of(false);
    }),
    map(() => true)
  );
};

const routes: Routes = [
  { path: '', redirectTo: 'deployments', pathMatch: 'full' },
  {
    path: 'login',
    component: CredentialsComponent,
  },
  {
    path: 'deployments',
    component: DeploymentsComponent,
    canActivate: [authGuard],
  },
  {
    path: 'activity',
    component: ActivityComponent,
    canActivate: [authGuard],
  },
  {
    path: 'analytics',
    component: AnalyticsComponent,
    canActivate: [authGuard],
  },
  {
    path: 'deploy/:slug',
    component: DeployComponent,
    canActivate: [authGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
