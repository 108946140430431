<app-header />

<div class="m-2">
  <h1>Job Queue</h1>

  <div class="overflow-y-scroll h-60">
    <table>
      <thead>
        <tr>
          <th scope="col">Updated</th>
          <th scope="col">Job</th>
          <th scope="col">Status</th>
          <th scope="col">Source</th>
          <th scope="col">Queued</th>
        </tr>
      </thead>
      <tbody>
        @for (job of jqueue; track job.id) {
          <tr>
            <td>{{ job.last_status_ts }}</td>
            <td>
              <span
                class="cursor-pointer underline text-blue-400"
                (click)="onViewOutput(job)"
              >
                {{ job.job_type }} ({{ job.job_id }})
              </span>
            </td>
            <td>{{ job.status }}</td>
            <td>{{ job.source }}</td>
            <td>{{ job.ingest_ts }}</td>
          </tr>
        }
      </tbody>
    </table>
  </div>

  <h1>Environments</h1>
  <table>
    <thead>
      <tr>
        <th scope="col">Slug</th>
        <th scope="col">Company</th>
        <th scope="col">Subscription Level</th>
        <th scope="col">Aliases</th>
        <th scope="col">Internal IP</th>
        <th scope="col">
          Environment <button (click)="onRefreshEnvironment()">refresh</button>
        </th>
        <th scope="col">
          Versions <button (click)="onRefreshVersions()">refresh</button>
        </th>
        <th scope="col">
          Weekly Users <button (click)="onRefreshData()">refresh</button>
        </th>
      </tr>
    </thead>
    <tbody>
      @for (comp of companies; track comp) {
        <tr
          [ngClass]="{
            'bg-slate-200': comp.claimed === 'trial',
            'text-gray-300': comp.claimed === 'preclaim',
          }"
        >
          <td>
            <div class="flex items-center">
              <input
                type="checkbox"
                [checked]="comp.selected"
                (change)="comp.selected = !comp.selected"
              />
              &nbsp;
              <a [href]="'/deploy/' + comp.org_slug">
                <span class="font-mono">{{ comp.org_slug }}</span>
              </a>
              &nbsp;
              <button
                [matMenuTriggerFor]="tenantMenu"
                [matMenuTriggerData]="{ tenant: comp }"
              >
                <span>☰</span>
              </button>
            </div>
          </td>
          <td>{{ comp.company }}</td>
          <td>
            <span
              [ngClass]="{
                'text-gray-400': comp.subs_level === 'regular',
              }"
            >
              {{ comp.subs_level }}
              {{
                comp.claimed === 'customer' ? '' : '('.concat(comp.claimed, ')')
              }}
            </span>
          </td>
          <td>
            <span class="font-mono">{{ comp.org_slug }}</span> {{ comp.alias }}
          </td>
          <td>
            {{ comp.status !== 'live' ? comp.status.toUpperCase() : '' }}
            {{ comp.internal_address }}
          </td>
          <td>{{ comp.environmentStats | environmentSummary }}</td>
          <td>{{ comp.versions | versionSummary }}</td>
          <td>{{ comp.dataStats | dataSummary }}</td>
        </tr>
      }
    </tbody>
  </table>

  <h1>Danger Section</h1>

  <div style="display: flex; gap: 8px">
    <button (click)="onUpdateProd()">update production</button>
    <button (click)="onUpdateAppdot()">update appdot/auth</button>
    <button (click)="onCreateTenant()">create tenant</button>
  </div>
</div>

<mat-menu #tenantMenu="matMenu">
  <ng-template matMenuContent let-tenant="tenant">
    <button mat-menu-item (click)="onInviteUser(tenant)">
      <mat-icon>person_add</mat-icon>
      <span>Invite</span>
    </button>
    @if (
      tenant.org_slug.startsWith('t0000') || tenant.org_slug.startsWith('t600')
    ) {
      <button mat-menu-item (click)="onUpdateUat()">
        <mat-icon>update</mat-icon>
        <span>Update</span>
      </button>
    }
    <button mat-menu-item (click)="onPowerDown(tenant)">
      <mat-icon>power_off</mat-icon>
      <span>Power Down</span>
    </button>
    <button mat-menu-item (click)="onDecommission(tenant)">
      <mat-icon>delete</mat-icon>
      <span>Decommission</span>
    </button>
    <button mat-menu-item (click)="onPowerUp(tenant)">
      <mat-icon>power</mat-icon>
      <span>Power Up</span>
    </button>
  </ng-template>
</mat-menu>

<ng-template #jobOutput let-output="output">
  <div class="h-[80vh] w-[70vw]">
    <div class="h-full overflow-scroll font-mono">
      @for (line of output; track line.index) {
        <p class="m-0">
          <span class="p-1 bg-slate-200 h-80 text-nowrap">
            {{ line.ts }}
          </span>
          <span
            class="p-1 text-nowrap whitespace-pre"
            [ngClass]="{
              'bg-slate-200': line.stream === 'control',
              'text-gray-600': line.stream === 'control',
              'text-red-500': line.stream === 'stderr',
            }"
          >
            {{ line.data }}
          </span>
        </p>
      }
    </div>
  </div>
</ng-template>

<ng-template #powerDownConfirm let-tenant="tenant">
  <p>
    Company: <b>{{ tenant.company }}</b>
  </p>
  <p>
    <span class="font-bold">DANGER</span> This will cause any active users to
    get a proxy error when accessing the tenant. This functionality is generally
    only useful to pre-claimed trial tenants.
  </p>
  <p>This does not delete the data backing this tenant.</p>
</ng-template>

<ng-template #decommissionConfirm let-tenant="tenant">
  <p>
    Company: <b>{{ tenant.company }}</b>
  </p>
  <p>
    <span class="font-bold">DANGER</span>
    This will mark the tenant decommissioned so it will receive no further data
    migrations or software updates. It will cause any active users to get a
    proxy error when accessing the tenant.
  </p>
  <p>This does not delete the data backing this tenant.</p>
</ng-template>

<ng-template #powerUpConfirm let-tenant="tenant">
  <p>
    Company: <b>{{ tenant.company }}</b>
  </p>
  <p>
    This will boot the machine so that users can access this tenant. It takes
    about 2 minutes.
  </p>
</ng-template>
